<template>
  <v-container></v-container>
</template>

<script>
export default {
  name: "DispenserDetail"
};
</script>

<style scoped></style>
